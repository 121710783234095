import React from "react";
import { Route, RouteComponentProps, Switch, withRouter } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Home from "./components/home/home";
import Quiz from "./components/quiz/quiz";
import Result from "./components/result/result";
import Contact from "./components/contact/contact";


const App = (routeProps: RouteComponentProps) => {
  return <TransitionGroup childFactory={(child) => React.cloneElement(
    child,
    { classNames: routeProps.history.action !== "POP" ? "slide" : "slide-back", timeout: 1000 })}>
    <CSSTransition key={routeProps.location.pathname} timeout={1000}>
      <Switch key={routeProps.location.pathname} location={routeProps.location}>
        <Route exact path="/" component={Home} />
        <Route exact path="/questionario" component={Quiz} />
        <Route exact path="/resultado/:id" component={Result} />
        <Route exact path="/contato" component={Contact} />
      </Switch>
    </CSSTransition>
  </TransitionGroup>
}
export default withRouter(App);
