// import registerServiceWorker from './registerServiceWorker';
import * as React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import App from "./App";
import { reduxStore } from "./store";

const rootElement = document.getElementById("root");
// Create an enhanced history that syncs navigation events with the store
ReactDOM.render(
  <Provider store={reduxStore}>
    <Router>
       <App/>
    </Router>
  </Provider>,
  rootElement,
);

// registerServiceWorker();
