import React from 'react';
import { connect } from "react-redux";
import { State } from "../../store";
import {
    createStyles,
    Theme,
    withStyles,
    makeStyles,
    createMuiTheme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import useForm from "./form";
import SendButton from "./SendButton";
import { Warning } from "../quiz/warning"
import axios from 'axios';
import { API } from '../../constants';

export interface FormValues {
    nome: string;
    email: string;
    telefone: string;
    [key: string]: string;
}

const CssTextField = withStyles({
    root: {
        'width': "19rem",
        '& label': {
            color: '#006096',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
                borderRadius: "40px",
            },
            '&:hover fieldset': {
                borderColor: '#006096',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        }
    },
})(TextField);

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        margin: {
            margin: theme.spacing(1),
        },
        formControl: {
            margin: theme.spacing(1),
        },
    }),
);

type ContactFormProps = {
    onSubmit: () => void,
    calculatorId: string
}

const mapStateToProps = ({ form }: State) => ({
    calculatorId: form.calculatorId,
});

const theme = createMuiTheme();
export const ContactForm = (props: ContactFormProps) => {
    const classes = useStyles(theme);
    const form = useForm();
    const error = useForm();
    const [showWarning, setShow] = React.useState(false);

    const validateInput = (event: any) => {
        const id: string = event.target.id;
        if (event.target.value === '') {
            error.set[id]('Campo obrigatório');
        }
        else {
            error.set[id](null);

            if (id === 'email' && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.email)) {
                error.set[id]('Endereço de e-mail inválido');
            }
            if (id === 'phone' && !/^^[0-9\-\+]{8,15}$/i.test(form.phone)) {
                error.set[id]('Telefone inválido');
            }
        }
    }

    const handleInputChange = (event: any) => {
        const id: string = event.target.id;
        form.set[id](event.target.value);
        error.clear[id]();
    }

    const handleSubmit = () => {
        //if at least one form is empty
        if (!form.name || !form.email || !form.phone) {
            setShow(true)
        }
        //if at least one form has error
        else if (error.name || error.email || error.phone) {
            setShow(true)
        }
        else {
            const payload = {                
                "name": form.name,
                "email": form.email,
                "telephone": form.phone,
                "calculatorId": props.calculatorId                  
            }

            axios
                .post(
                    `${API}/UsersControllers/`, payload)
                .then((res) => {
                    props.onSubmit()
                })
                .catch((err) => {
                    console.log('unable to connect server')
                    console.log(err)
                });            
        }
    }

    const errorForm = error.name || error.email || error.phone

    return (
        <>
            <div className={classes.root}>
                <FormControl className={classes.formControl} error={errorForm}>
                    <div className='mb-1'>
                        <CssTextField
                            autoComplete="off"
                            id="nome"
                            label="Nome"
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={(event) => validateInput(event)}
                            error={error.name}
                        />
                        <FormHelperText >{error.name}</FormHelperText>
                    </div>
                    <div className='mb-1'>
                        <CssTextField
                            id="email"
                            label="E-mail"
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={(event) => validateInput(event)}
                            error={error.email}
                        />
                        <FormHelperText>{error.email}</FormHelperText>
                    </div>
                    <div className='mb-1'>
                        <CssTextField
                            id="phone"
                            label="Telefone"
                            variant="outlined"
                            onChange={handleInputChange}
                            onBlur={(event) => validateInput(event)}
                            error={error.phone}
                        />
                        <FormHelperText>{error.phone}</FormHelperText>
                    </div>
                </FormControl>
            </div>
            <SendButton onClick={handleSubmit} />
            <Warning positionStyle={{
                bottom: "2rem", left: 0,
                right: 0,
                marginLeft: "auto",
                marginRight: "auto",
            }}
                show={showWarning}
                onBlur={() => setShow(false)}
            />
        </>
    );
}
export default connect(mapStateToProps)(ContactForm);
