import React from "react";
import { connect } from "react-redux";
import useAspectStatus from "../portrait";
import EnergyInput from "./EnergyInput";
import { EnergyLamps } from "./EnergyLamps";
import { EnergyQuestion } from "./EnergyQuestion";
import { EnergyInfo } from "./EnergyInfo";
import Container from "../grid/Container";
import Background from "../grid/Background";
import { AldoLogo } from "../AldoLogo";

export const Energy = () => {
  const isAspect = useAspectStatus();
  return <Background color={"#FFAB72"}>
    {!isAspect && <AldoLogo />}
    <Container adjust={isAspect ? "flex-column-reverse" : ""}>
      <EnergyQuestion isPortrait={isAspect} />
      <EnergyLamps isPortrait={isAspect} totalQuantity={4} onQuantity={2} />
    </Container>
    <Container>
      <EnergyInput isPortrait={isAspect} />
    </Container>
    <EnergyInfo isAspect={isAspect} />
  </Background>;
};

export default connect()(Energy);
