import React from "react";
import { connect } from "react-redux";
import { css } from "emotion";
import useAspectStatus from "../portrait";

type Props = {
    onClick: () => void
}

export const SendButton = (props: Props) => {
    const isAspect = useAspectStatus();
    const buttonClass = css({
        fontSize: "1.2rem",
        color: "white",
        borderWidth: 0,
        borderRadius: "2.5rem",
        width: "12rem",
        height: !isAspect ? "3rem" : "2.7rem",
        padding: "0 2rem 0 2rem",
        marginBottom: "1rem",
        backgroundColor: "#006096"
    })

    return <button className={buttonClass} onClick={props.onClick}>ENVIAR</button>
};

export default connect()(SendButton);