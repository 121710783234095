import { Col } from "reactstrap";
import sunSvg from "./images/Sun.svg";
import cloudRightSvg from "./images/CloudRight.svg";
import cloudLeftSvg from "./images/CloudLeft.svg";
import cityPinsSvg from "./images/CityPins.svg";
import citySvg from "./images/City.svg";
import React from "react";

import "./homeBackground.css";

export const HomeBackground = () => (
  <div className={"d-flex w-100 h-100 position-absolute"}>
    <div className="d-flex w-100 h-100 position-absolute">
      <Col
        xl={"2"}
        md={"2"}
        sm={"3"}
        xs={"4"}
        className="ml-sm-2 ml-md-5 mt-md-4 mt-3 ml-md-3"
      >
        <img
          className={"pl-5 ml-md-5 mr-md-n5 align-self-start position-absolute animateSun"}
          src={sunSvg}
          alt={""}
        />
      </Col>
    </div>
    <div className="d-flex w-100 h-100 position-absolute justify-content-start overflow-hidden">
      <div className="col-6" />
      <img
        className={"align-self-start mt-4 ml-5 pr-n5 mr-n5 pt-5 pl-5 animateCloud"}
        style={{ width: "12rem" }}
        src={cloudRightSvg}
        alt={""}
      />
    </div>
    <div className="d-flex w-100 h-100 position-absolute justify-content-end align-items-center overflow-hidden">
      <div style={{ height: "40%" }}>
        <img
          className={"mt-n4 mb-4 pr-n5 mr-n3 mr-sm-n3 mr-md-0 mr-lg-5 pb-5 animateCloud"}
          style={{ width: "5rem" }}
          src={cloudRightSvg}
          alt={""}
        />
      </div>
    </div>
    <div className="d-flex w-100 h-100 position-absolute justify-content-start align-items-center overflow-hidden">
      <div className="col-1 ml-n5 p-0" />
      <img
        className="mt-4 pl-n5 ml-n3 ml-sm-0 ml-md-2 ml-lg-5 pt-4 animateCloud"
        style={{ width: "7.5rem" }}
        src={cloudLeftSvg}
        alt={""}
      />
    </div>
    <div className="d-flex w-100 h-100 position-absolute justify-content-start align-items-end overflow-hidden">
      <div className="col-3 ml-n5 p-0" />
      <img
        className="mb-5 pl-n5 ml-n3 ml-sm-0 ml-md-2 ml-lg-5 pb-5 animateCloud"
        style={{ width: "5rem" }}
        src={cloudLeftSvg}
        alt={""}
      />
    </div>
    <div className="d-flex w-100 h-100 position-absolute justify-content-end align-items-end overflow-hidden">
      <div className="col-md-5 col-9 mr-n5" style={{ maxHeight: "60%" }}>
        <img
          className="mb-n4 p mr-n4 position-absolute animatePins"
          src={cityPinsSvg}
          alt=""
        />
        <img className="mb-n4 p mr-n5" src={citySvg} alt="" />
      </div>
    </div>
  </div>
);
