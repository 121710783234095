import React from "react";
import { connect } from "react-redux";
import Background from "../grid/Background";
import Container from "../grid/Container"
import useAspectStatus from "../portrait";
import aldoLogoSvg from "../home/images/Aldo.svg";
import { ContactQuestion } from "./Question";
import ContactForm from "./ContactForm"
import Thanks from "./Thanks"

const Content = () => {
    const isAspect = useAspectStatus();
    const [submitForm, setSubmit] = React.useState(false);

    if(submitForm){
       return <Thanks isPortrait={isAspect}/>
    }       
    else{
        return <> 
        <ContactQuestion isPortrait={isAspect} />
        <ContactForm onSubmit={() => setSubmit(true)}/> 
        </>
    }
}

export const Contact = () => {
    const isAspect = useAspectStatus();
    
    return <Background color={"#C1EAF9"}>
        <Container>
            <div className={"d-flex mb-5 px-3"}>
                <img src={aldoLogoSvg} alt="" style={{ width: !isAspect ? "33rem" : "18rem", }} />
            </div>             
            <Content />
        </Container>
    </Background>
};

export default connect()(Contact);