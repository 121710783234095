import { useEffect, useState } from "react";

let canUpdate = true;
let ratio = 1.4;

export function inputOn(inputOn: boolean){
  canUpdate = !inputOn;
}

export default function useAspectStatus() {
    const [isAspect, setAspect] = useState(true);

    useEffect(() => {
      const interval = setInterval(() => {
        const aspect = window.innerHeight / window.innerWidth;
        if(!canUpdate){
          clearInterval(interval);
          return;
        } 
        setAspect(aspect > ratio);
      }, 200);
      return () => {
        clearInterval(interval);
      };
    }, [isAspect]);

    return isAspect;
  }
