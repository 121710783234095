import React from "react";

type Props = {
  isPortrait: boolean;
};

export const ContactQuestion = (props: Props) => (
  <h2
    style={{
      color: "#006096",
      fontWeight: "lighter",
      fontFamily: "Quicksand",
      fontSize: props.isPortrait ? "1.7rem" : "2rem",
      width:  props.isPortrait ? "18rem" : "33rem" ,
      marginBottom: "1.5rem",
      textAlign: "center"
    }}
  >
    Preencha seus dados e uma revendedora ALDO SOLAR entrará em contato.
  </h2>
);
