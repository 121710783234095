import * as React from "react";
import { connect } from "react-redux";
import { State } from "../../store";
import { Route, Switch, withRouter } from "react-router";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import Financial from "../financial/financial";
import { Location } from "../location/Location";
import useAspectStatus from "../portrait";
import Result from "../result/result";
import InstallationStructure from "../structure/InstallationStructure";
import { Arrow } from "./arrow";
import { Warning } from "./warning"
import backwardArrow from "./images/BackwardArrow.svg";
import forwardArrow from "./images/ForwardArrow.svg";
import useNavigator from "./navigator";
import { classes } from "./styles";
import { Energy } from "../energy/Energy";
import classNames from "classnames";
import { checkLocation, checkFinancial, checkEnergy } from "./checkers"
//import { API } from '../../constants';
//import axios from 'axios'

const mapStateToProps = ({ form }: State) => ({
  locationForm: form.location,
  financialForm: form.financial,
  structureForm: form.structure,
});

const Quiz = (props: any) => {
  const components = [Location, Financial, Energy, InstallationStructure, Result];
  const componentsCheckers = [
    checkLocation(props.locationForm),
    checkFinancial(props.financialForm),
    checkEnergy(props.financialForm)
  ];

  const totalPages = components.length;
  const navigator = useNavigator(1, totalPages-1);
  const isPortrait = useAspectStatus();
  const [showWarning, setShow] = React.useState(false);

  const navigateNext = () => {
    if (navigator.index - 1 < componentsCheckers.length)
      if (!componentsCheckers[navigator.index - 1]) {
        setShow(true)
        return
      }
      navigator.next();
      if (navigator.index === totalPages - 1) {      
          props.history.push(`/resultado/1`)
        //   const payload = {
          //     structure: props.structureForm.type,
          //     location: props.locationForm,
          //     buildings: props.financialForm.bills
          //   }
       }   //ALTERAR AQUI A CHAMADA PARA API NOVA QUE TRÁS OS RESULTADOS
      // axios
      //   .post(
      //     `${API}/CalculatorsControllers/`, payload)
      //   .then((res) => {
      //     props.history.push(`/resultado/${res.data.id}`);
      //   })
      //   .catch((err) => {
      //     console.log('unable to load results from server')
      //     console.log(err)
      //   });      
    //}

  }

  return (
    <div>
      <TransitionGroup
        childFactory={(child) =>
          React.cloneElement(child, {
            classNames:
              navigator.forward ? "slide" : "slide-back",
            timeout: 1000,
          })
        }
      >
        <CSSTransition key={"key" + navigator.index} timeout={1000}>
          <Switch key={navigator.index} location={props.location}>
            <Route exact={true} path="/questionario" component={components[navigator.index - 1]} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
      {navigator.index !== totalPages &&
        <span className={classNames("position-absolute text-center", classes.pageCount)}
          style={!isPortrait ? { right: "2rem", top: "2rem" } : { bottom: "2rem", right: 0, left: 0 }}
        >{navigator.index}/{totalPages - 1}</span>}
      {navigator.index !== 1 &&
        <Arrow positionStyle={!isPortrait ? { top: "50%", left: "5%" } : { bottom: "2rem", left: "5%" }}
          iconPath={backwardArrow}
          onClick={() => navigator.prev()} />
      }
      <Warning positionStyle={!isPortrait ? { top: "55%", right: "5%" } : {
        bottom: "2rem", left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
      }}
        show={showWarning}
        onBlur={() => setShow(false)}
      />
      <Arrow positionStyle={!isPortrait ? { top: "50%", right: "5%" } : { bottom: "2rem", right: "5%" }} iconPath={forwardArrow}
        onClick={navigateNext} />
    </div>
  );
};

export default connect(mapStateToProps)(withRouter(Quiz));
