import React from "react";
import { connect } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { State } from "../../store";
import { actions } from "../../store/form";
import { AldoLogo } from "../AldoLogo";
import Background from "../grid/Background";
import Container from "../grid/Container";
import useAspectStatus from "../portrait";
import useNavigator from "../quiz/navigator";
import { classes } from "../warning/styles";
import { Warning } from "../warning/warning";
import InstallationStructureCarousel from "./InstallationStructureCarousel";
import { InstallationStructureQuestion } from "./InstallationStructureQuestion";
import groundImage from "./images/Ground.svg";
import slabImage from "./images/Slab.svg";
import colonialImage from "./images/Colonial.svg";
import metalImage from "./images/Metal.svg";
import "./css/structureAnimation.css";

const structures = ["Solo", "Laje", "Telhado colonial", "Telhado metálico"];
const structureImages = [groundImage, slabImage, colonialImage, metalImage];
const mapStateToProps = ({ form }: State) => ({
  structure: form.structure
});

const dispatchProps = {
  editStructureType: actions.editStructureType,
};

export const InstallationStructure = (props: any) => {
  const isAspect = useAspectStatus();
  const initialIndex = structures.indexOf(props.structure.type);
  const navigator = useNavigator(0, structures.length - 1, true, initialIndex);
  const next = () => {
    navigator.next();
    // eslint-disable-next-line
    const index = navigator.index + 1 == structures.length ? 0 : navigator.index + 1;
    props.editStructureType(structures[index]);
  };

  const prev = () => {
    navigator.prev();
    const index = navigator.index - 1 < 0 ? structures.length - 1 : navigator.index - 1;
    props.editStructureType(structures[index]);
  };

  return (
    <Background color={"#88DBC4"}>
      {!isAspect && <AldoLogo />}
      <Container>
        <InstallationStructureQuestion isPortrait={isAspect} />
      </Container>
      <Container>
        <div className="d-flex flex-column">
          <InstallationStructureCarousel isPortrait={isAspect} structures={structures}
            handleNext={next} handlePrev={prev} index={navigator.index} />
          <CSSTransition key={navigator.index} timeout={2000}>
            <img className={"structure-fade"} src={structureImages[navigator.index]} alt=""
              style={{ width: isAspect ? "20rem" : "30rem", height: isAspect ? "20rem" : "23rem" }} />
          </CSSTransition>
        </div>
      </Container>
      <Container adjust={"mb-5"}>
        <Warning text={"Um único gerador de energia solar pode suprir toda a sua necessidade. A estrutura utilizada " +
          "depende do local onde você quer instalar o seu gerador."}
          css={classes.infoLocationText}
          width={isAspect ? "20rem" : "26rem"} />
      </Container>
    </Background>
  );
};

export default connect(mapStateToProps, dispatchProps)(InstallationStructure);
