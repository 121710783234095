import solarData from "./data_solar.json";
import axios from 'axios';
import { API_WCF } from '../../../constants';

export interface Location {
  municipio: string;
  latitude: number;
  longitude: number;
  uf: string;
  estado: string;
  incidencia_solar_anual: number;
  incidencia_solar_mensal: {};
  [key: string]: any;
}
export const getStates: () => Promise<string[]> = async () => { 
  return Array.from(new Set(solarData.map((item: Location) => item.estado))) 
  let url = `${API_WCF}/CalculadoraSolar.svc/Locations`;
  let locations = await axios.get(url).then((response) => { 
    return response.data;
  })

  return Array.from(new Set(locations.map((item: { State: any; }) => item.State)))
};
export const getEntryList: () => Promise<string[]> = async () => { 
  return Array.from(new Set(solarData.map((item: Location) => item.uf))); 
  let url = `${API_WCF}/CalculadoraSolar.svc/Locations`;
  let locations = await axios.get(url).then((response) => { 
    return response.data;
  })

  locations.forEach((element: any) => {
    // eslint-disable-next-line
    element.uf = solarData.filter((c => c.estado == element.State))[0].uf;
  });

  return Array.from(new Set(locations.map((item: Location) => item.uf)));
}
export const getCitiesByState = async (state: string) => {
  return solarData.filter((item: Location) => item.estado === state)
     .map(((item: Location) => (item.municipio)));
  let url = `${API_WCF}/CalculadoraSolar.svc/Locations`;
  let locations = await axios.get(url).then((response) => { 
    return response.data;
  })

  let cities = locations.filter((item: any) => item.State === state).map(((item: any) => (item.City)));
  return cities;
}
export const getAnnualSolarIncidence = async (city: string) => {
  //let url = `${API_WCF}/CalculadoraSolar.svc/Location/${city}`;
  //return await axios.get(url).then((response) => { 
  //  return response.data.SolarIncidence;
  //})
  return solarData.filter((item: Location) => item.municipio === city)
     .pop().incidencia_solar_anual;
}  

