import { FormState } from "../../store/form";

export const checkLocation = (location: FormState["location"]) => (
    location.city !== null && location.state !== null
)

export const checkFinancial = (financial: FormState["financial"]) => (
    financial.bills.map(bill => bill.value !== 0).every(item => item === true)
)

export const checkEnergy = (financial: FormState["financial"]) => (
    financial.bills.map(bill => bill.powerConsumption !== 0).every(item => item === true)
)