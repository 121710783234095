import React from 'react';
import Container from "../grid/Container"
import {classes as classesWarning} from "../warning/styles";
import {Warning} from "../warning/warning";
import MeanBillValue from "./meanBillValue"

type FinancialInfoProps = {
    isAspect: boolean
}

export const FinancialInfo = (props: FinancialInfoProps) => {
    // eslint-disable-next-line
    const warningText = "Você pode adicionar contas de luz de diferentes locais que utiliza, \
    e economizar cada vez mais, contanto que as contas sejam do mesmo CPF ou CNPJ. ";
    return <Container isResponsive={true} adjust={"mb-5"}>
        < MeanBillValue />
        <Warning text={warningText}
            css={classesWarning.infoLocationText} width={props.isAspect ? "22rem" : "26rem"} />
    </Container>
}
