import React from "react";
import {connect} from "react-redux";
import "./css/moneyAnimation.css";
import Money from "./images/Money.svg";
import MoneyPack from "./images/MoneyPack.svg";

type Props = {
  value: number;
}

const createMoneyList = (total: number, offset: number) => {
  const images: JSX.Element[]= [];

  let top = -offset;
  for(let i = 0; i<total; i++){

    images.push(<img src={Money} alt="" className="position-absolute money-fade"
                     style={{width: "8.0rem", top: top+"rem", left: "2rem"}}/>);
    top -= offset;
  }
  return images
};

const BillAnimatedMoney = (props: Props) => {
  const qtdExtraMoney = props.value <= 0 ? 0 : Math.min(Math.log10(props.value), 10);
  const extraMoney = createMoneyList(qtdExtraMoney, 0.4);
  return <div className="d-flex position-relative unselectable">
    <img src={MoneyPack} alt="" style={{width: "10.0rem"}}/>
    {extraMoney}
  </div>;
};

export default connect()(BillAnimatedMoney);
