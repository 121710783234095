import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import leftArrowSVG from "./images/ArrowLeft.svg";
import rightArrowSVG from "./images/ArrowRight.svg";

type Props = {
  isPortrait: boolean;
  structures: string[];
  handleNext: () => void;
  handlePrev: () => void;
  index: number
};

const generateCarouselContent = (structures: string[], isPortrait: boolean, activeIndex: number) => {
  const billsDivs: JSX.Element[] = [];

  const size = isPortrait ? "1.2rem" : "1.5rem";
  const textStyle = {
    color: "#FFFFFF",
    fontSize: size,
    fontFamily: "Quicksand",
    fontWeight: 700,
    lineHeight: size
  };

  structures.forEach((structure, index) => {
    billsDivs.push(
      <div
        key={`structure ${index}`}
        // eslint-disable-next-line
        className={classNames("carousel-item", { active: index == activeIndex })}
      >
        <p className="text-center" style={textStyle}>
          {structure}
        </p>
      </div>
    );
  });
  return billsDivs;
};

const InstallationStructureCarousel = (props: Props) => {
  const { isPortrait, structures } = props;
  const carouselContent = generateCarouselContent(structures, isPortrait, props.index);

  const height = isPortrait ? "1.875rem" : "2.1875rem";
  const width = height;

  const leftArrow = {
    backgroundImage: `url('${leftArrowSVG}')`,
    height,
    width
  };
  const rightArrow = {
    backgroundImage: `url('${rightArrowSVG}')`,
    height,
    width
  };

  return (
    <div
      id="carouselControls"
      className="carousel slide w-100"
      data-ride="carousel"
      data-interval="false"
    >
      <div className="carousel-inner">{carouselContent}</div>
      <a
        className="carousel-control-prev"
        href="#carouselControls"
        role="button"
        data-slide="prev"
        onClick={props.handlePrev}
        style={{ opacity: 1 }}
      >
        <span
          className="carousel-control-prev-icon"
          aria-hidden="true"
          style={leftArrow}
        />
        <span className="sr-only">Anterior</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carouselControls"
        role="button"
        data-slide="next"
        onClick={props.handleNext}
        style={{ opacity: 1 }}
      >
        <span
          className="carousel-control-next-icon"
          aria-hidden="true"
          style={rightArrow}
        />
        <span className="sr-only">Próxima</span>
      </a>
    </div>
  );
};

export default connect()(InstallationStructureCarousel);
