import React from "react"

 type AddBillButtonProps = {
     onClick: () => {}
 }

export const AddBillButton = (props: AddBillButtonProps) => {
    const style = {
        fontFamily: "Quicksand",
        fontSize: "1.0rem",
        color: "#8445CC",
        backgroundColor: "#E4ACFF",
        borderWidth: "0",
        borderRadius: "4rem",
        marginTop:"1rem"
    };

    return <button className="p-2 px-3" style={style} onClick={props.onClick} >
        Adicionar conta de outro imóvel
    </button>

};
