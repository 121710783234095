import React from "react";
import { connect } from "react-redux";
import { actions } from "../../store/form";
import { Link } from "react-router-dom";
import { css } from "emotion";
import useAspectStatus from "../portrait";

type Props = {
    isPortrait: boolean,
    resetForm: Function
};

const dispatchProps = {
    resetForm: actions.resetForm,
  };
  
export const Thanks = (props: Props) => {
    const isAspect = useAspectStatus();

    const buttonClass = css({
        fontSize: "1.2rem",
        color: "white",
        borderWidth: 0,
        borderRadius: "2.5rem",
        width: "18rem",
        height: !isAspect ? "3rem" : "2.7rem",
        padding: "0 2rem 0 2rem",
        marginBottom: "1rem",
        backgroundColor: "#006096"
    })

    return <>
        <div
            style={{
                color: "#4ECFFF",
                fontWeight: "bold",
                fontFamily: "Quicksand",
                fontSize: props.isPortrait ? "3rem" : "4rem",
                marginBottom: "1.5rem",
                textAlign: "center"
            }}
        >
            Obrigado!
  </div>
    <div
            style={{
                color: "#006096",
                fontWeight: "lighter",
                fontFamily: "Quicksand",
                fontSize: props.isPortrait ? "1.4rem" : "1.6rem",
                width: props.isPortrait ? "18rem" : "25rem",
                marginBottom: "1.5rem",
                textAlign: "center",
                lineHeight: "2.4rem"
            }}
        >
            A energia positiva, energia solar, está prestes a fazer parte do seu dia a dia!
  </div>
  <a href='https://materiais.aldo.com.br/seja-um-revendedor-aldo'>
    <button className={buttonClass}>SEJA REVENDEDOR</button>
   </a>
   <Link to="/"> 
    <button className={buttonClass} onClick={() => props.resetForm()}>CALCULAR NOVAMENTE</button>
   </Link>  
 </>
};

export default connect(null, dispatchProps)(Thanks);

