import React, {FunctionComponent} from 'react';
import classNames from "classnames"

interface ContainerProps {
  isResponsive?: boolean,
  row?: boolean,
  adjust?: string
}

const defaultProps: ContainerProps = {
  isResponsive: false,
  row: false,
  adjust: ""
};

//Column by default, in small screens transforms to row if responsive
const Container: FunctionComponent<ContainerProps> = (props) => {
  const containerClass = classNames("d-flex flex-fill justify-content-center align-items-center",
    {
      "flex-column": !props.row,
      "flex-row": props.row,
      "flex-md-row ": props.isResponsive && !props.row,
    }, props.adjust);

  return <div className={containerClass}>
    {props.children}
  </div>
};

Container.defaultProps = defaultProps;

export default Container;
