import React from "react";
import content from "./Info.svg";

type WarningProps = {
  text: string,
  width: string,
  css: any,
};

export const Warning = (props: WarningProps) => (
  <div className="d-flex flex-row justify-content-center align-items-center" style={{width: props.width}}>
    <img className="p-2" style={{width: "3.5rem"}} src={content} alt={""}/>
    <span className={props.css}>{props.text}</span>
  </div>
);
