
import { API } from '../../constants';

const sum = (x: number[]) => x.reduce((p, c) => p + c, 0);

export const getMinGeneratorCapacity = (billsEnergy: number[], solarIncidence: number) => {
    if (solarIncidence !== 0)
        return sum(billsEnergy) / (30 * solarIncidence);
    else
        return 0;
}

export const getValueKwh = (billsValue: number[], billsEnergy: number[]) => {
    if (sum(billsEnergy) !== 0)
        return sum(billsValue) / sum(billsEnergy);
    else
        return 0;
}

export const getProducedEnergyPerYear = (billsEnergy: number[]) => {
    return sum(billsEnergy) * 12;
}

export const getEconomyPerYear = (billsValue: number[], billsEnergy: number[]) => {
    const valueKwh = getValueKwh(billsValue, billsEnergy);
    const producedEnergy = getProducedEnergyPerYear(billsEnergy);
    return producedEnergy * valueKwh;
}

//source: https://www.portalsolar.com.br/placa-solar-preco.html
export const getInitialInvestment = (generatorCapacity: number) => {
    var request = new XMLHttpRequest();
    request.open('GET', `${API}/CalculatorsControllers/precoMedioPotencia/${generatorCapacity}`, true);  // `false` makes the request synchronous
    request.send(null);
    return Number(request.responseText);
}

