import React, { CSSProperties } from "react"
import NumberFormat from "react-number-format"
import { inputOn } from "../portrait";

type BillValueInputProps = {
    handleChange: (event: React.ChangeEvent<{}>, newValue: number) => void,
    value: number,
    defaultValue: number
}

export const BillValueInput = (props: BillValueInputProps) => {
    const { handleChange, value, defaultValue } = props
    // eslint-disable-next-line
    React.useEffect(() => { handleChange(null, props.defaultValue) }, [defaultValue]);

    const inputStyle: CSSProperties = {
        fontFamily: "Quicksand",
        fontSize: "1.0rem",
        color: "white",
        backgroundColor: "#8445CC",
        textAlign: "center",
        borderWidth: "0",
        borderRadius: "40px",
        width: "240",
        height: "30",
        padding: "1vh 5vw"
    };

    return <NumberFormat
        style={inputStyle}
        thousandSeparator={"."}
        decimalSeparator={","}
        prefix={'R$  '}
        displayType={"input"}       
        value={value}
        defaultValue={defaultValue}
        onFocus={(event) => {
            inputOn(true);
        }}
        onBlur={() => {           
            inputOn(false);
        }}
        onValueChange={(values) => {
            const { floatValue } = values;
            handleChange(null, floatValue)
        }}
        isAllowed={(values) => {
            const {floatValue} = values;
            return floatValue >= 0 &&  floatValue <= 9999;
          }}
    />
};
