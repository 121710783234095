import React from "react";
import { Link } from "react-router-dom";
import { Warning } from "../warning/warning";
import { classes } from '../warning/styles'
import useAspectStatus from '../portrait'
import aldoLogoSvg from "./images/Aldo.svg";

export const HomeContent = () => {
  const isPortrait = useAspectStatus();

  return <div className="d-flex flex-column position-absolute w-100 h-100">
    <div className="d-flex p-3">
      {!isPortrait && (
        <img src={aldoLogoSvg} alt="" style={{ width: "9rem" }} />
      )}
      <div className="d-flex ml-auto align-items-center">
        <a
          className="login-button px-4 py-2"
          style={{ fontSize: "1rem" }}
          href="http://www.aldo.com.br/login"
        >FAZER LOGIN
        </a>
      </div>
    </div>
    <div className="d-flex flex-fill flex-column justify-content-center align-items-center">
      <h1
        className={"text-center w-50 mb-2"}
        style={{
          color: "#006096",
          fontSize: "2.3rem",
          fontWeight: "lighter",
        }}
      >Quanto você ganha investindo em energia solar?
      </h1>
      <Link to="/questionario">
        <button className={"saber-mais px-5 p-2"}>QUERO SABER!</button>
      </Link>
      <div className="d-flex p-3" />
      <Warning text={"Tenha sua conta de energia em mãos."}
        css={classes.infoText} width={'14rem'}
      />
    </div>
    <div className={"d-flex p-3"}>
      {isPortrait && (
        <img src={aldoLogoSvg} alt="" style={{ width: "9rem" }} />
      )}
    </div>
  </div>;
};
