import React from "react";
import {Helmet} from "react-helmet";
import { connect } from "react-redux";
import { HomeBackground } from "./homeBackground";
import { HomeContent } from "./homeContent";

export const Home = () => (
    <div className="vw-100 vh-100 position-absolute" style={{ backgroundColor: "#7ADBFF" }}>
      <Helmet>
        <style>{'body { background-color: #7ADBFF; }'}</style>
      </Helmet>
      <HomeBackground />
      <HomeContent />
    </div>
);

export default connect()(Home);
