import {Reducer} from 'redux';
import {BillFinancial} from "../components/financial/financial";

export type FormLocation = {
  state: string,
  city: string,
  solarIncidence: number
}

export type FormFinancial = {
  bills: Array<BillFinancial>
}

export type FormStructure = {
  type: string
}

export type FormState = {
  location: FormLocation,
  financial: FormFinancial,
  structure: FormStructure,
  calculatorId: string
}

export type Action =
  | { type: 'form/setState', state: string, city: string, solarIncidence: number }
  | { type: 'form/setCity', city: string, solarIncidence: number }
  | { type: 'form/addNewBill' }
  | { type: 'form/editBillValue', index: number, value: number }
  | { type: 'form/editEnergyBillValue', index: number, value: number }
  | { type: 'form/editBillType', index: number, billType: string }
  | { type: 'form/editStructureType', structureType: string}
  | { type: 'form/resetForm'}
  | { type: 'form/setForm', form: FormState}

const initialState: FormState = {
  location: {
    city: null,
    state: null,
    solarIncidence: 0
  },
  financial: {
    bills: [{type: 'residencial', value: 0, powerConsumption: 0}]
  },
  structure: {
    type: "Solo"
  },
  calculatorId: null
};

export const reducer: Reducer<FormState, Action> = (state = initialState, action) => {
  switch (action.type) {
    case 'form/setState':
      return {...state, location: {city: action.city, state: action.state, solarIncidence: action.solarIncidence}};
    case 'form/setCity':
      return {...state, location: {...state.location, city: action.city, solarIncidence: action.solarIncidence}};
    case 'form/addNewBill':
      if (state.financial.bills.length === 5)
        return {...state};
      const bills = state.financial.bills.concat({type: 'residencial', value: 0, powerConsumption: 0});
      return {...state, financial: {bills}};
    case 'form/editBillValue':
      return {
        ...state, financial: {
          bills: state.financial.bills.map(
            (bill, i) => i === action.index ? {...bill, value: action.value} : bill)
        }
      };
    case 'form/editEnergyBillValue':
      return {
        ...state, financial: {
          bills: state.financial.bills.map(
            (bill, i) => i === action.index ? {...bill, powerConsumption: action.value} : bill)
        }
      };
    case 'form/editBillType':
      return {
        ...state, financial: {
          bills: state.financial.bills.map(
            (bill, i) => i === action.index ? {...bill, type: action.billType} : bill)
        }
      };
    case 'form/editStructureType':
      return{
        ...state, structure: {
          type: action.structureType
        }
      };
    case 'form/resetForm':
      return initialState;

    case 'form/setForm':
      return action.form

    default:
      return state;
  };  
};

const setState = (state: string, city: string, solarIncidence: number): Action => ({
  type: 'form/setState',
  state,
  city,
  solarIncidence
});
const setCity = (city: string, solarIncidence: number): Action => ({type: 'form/setCity', city, solarIncidence});
const addNewBill = (): Action => ({type: 'form/addNewBill'});
const editBillValue = (index: number, value: number): Action => ({type: 'form/editBillValue', index, value});
const editEnergyBillValue = (index: number, value: number): Action => ({
  type: 'form/editEnergyBillValue',
  index,
  value
});
const editBillType = (index: number, billType: string): Action => ({type: 'form/editBillType', index, billType});
const editStructureType = (structureType: string): Action => ({type: 'form/editStructureType', structureType});
const resetForm = () : Action =>  ({type: 'form/resetForm'});
const setForm = (form: FormState) : Action => ({type: 'form/setForm', form});

export const actions = {setState, setCity, addNewBill, editBillValue, editBillType,
   editEnergyBillValue, editStructureType, resetForm, setForm};
