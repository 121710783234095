import React, {useEffect} from "react";
import {connect} from "react-redux";
import {State} from "../../store";
import {FormFinancial} from "../../store/form";
import useAspectStatus from "../portrait";

type BillListProps = {
    financial: FormFinancial,
    onSelectBill: (index: number) => void
};

const mapStateToProps = ({ form }: State) => ({
    financial: form.financial,
});

type BillIndexProps = {
    index: number
    isSelected: boolean
    onClick: (index: number) => void
    isPortrait: boolean
}

const BillIndex = (props: BillIndexProps) => {
    const style = {
        borderBottom: "1vh solid #8445CC",
        color: "#FFFFFF",
        cursor: "pointer",
        fontFamily: "Quicksand",
        fontSize: "1.1375rem",
        fontWeight: 500,
        lineHeight: "1.3875rem",
        margin: "2vh 1vw",
        opacity: !props.isSelected ? 0.36 : 1,
        paddingBottom: "1vh"
      };
    
      const stylePortrait = {
        backgroundColor: "#8445CC",
        borderRadius: "0 0 27pt 27pt",
        color: "#FFFFFF",
        fontFamily: "Quicksand",
        fontSize: "1.5625rem",
        fontWeight: 500,
        height: "6vh",
        marginLeft: "0.5rem",
        marginRight: "0.5rem",
        opacity: !props.isSelected ? 0.36 : 1,
        width: "13vw"
      };

    return (
        <div onClick={() => props.onClick(props.index)}>
          <div className="" style={!props.isPortrait ? style : stylePortrait}>
            {props.isPortrait ? `${props.index + 1}` : `CONTA ${props.index + 1}`}
          </div>
        </div>
      );
};

export const BillList = (props: BillListProps) => {
    const bills = props.financial.bills;
    const [length, setLength] = React.useState(bills.length);
    const [index, setIndex] = React.useState(length - 1);
    const onSelectBill = (index: number) => { setIndex(index); props.onSelectBill(index) };
    const isPortrait = useAspectStatus();

    //Always select the last bill when render component
    useEffect(() => {
        if (bills.length !== length)
            setIndex(bills.length - 1);
        props.onSelectBill(index);
        setLength(bills.length);
    });

    return <div className="d-flex flex-row text-center justify-content-center align-items-center">
        {bills.map((bill, i) =>
            <BillIndex key={i} index={i} isSelected={index === i} onClick={onSelectBill} isPortrait={isPortrait} />)}
    </div>
};

export default connect(mapStateToProps)(BillList);
