import React, {FunctionComponent} from 'react';
import {Helmet} from "react-helmet";

type BackgroundProps = {
  color: string
};

const Background: FunctionComponent<BackgroundProps> = (props) => (
  <div className={"vw-100 vh-100 position-absolute"} style={{backgroundColor: props.color}}>
    <Helmet>
      <style>{`body { background-color: ${props.color}; }`}</style>
    </Helmet>
    <div className="d-flex flex-column position-absolute w-100 h-100">
      {props.children}
    </div>
  </div>
);

export default Background;
