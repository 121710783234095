import React from "react"

import { connect } from "react-redux";
import { State } from "../../store";
import { FormFinancial } from "../../store/form";

type MeanBillValueProps = {
    financial: FormFinancial,
};

const mapStateToProps = ({ form }: State) => ({
    financial: form.financial,
});

export const MeanBillValue = (props: MeanBillValueProps) => {
    const sum =  (arr: number[]) =>  arr.reduce((p, c) => p + c, 0);

    return <button className="p-2 px-3 mean-result mb-1"> Média de gastos com energia:
    R$ {sum(props.financial.bills.map(item => item.value)).toFixed(2).replace(".", ",")}</button>
}
export default connect(mapStateToProps)(MeanBillValue);
